:root{
  --color-main:#2a3c81;
  --btn-primary:#2da61b;
  --btn-border-primary:#2da61b;

  --btn-primary-hover:#2d7b21;
  --btn-border-primary-hover:#194612;
}
html,
body,
#map {
    min-height: 69vh;
    margin: 0;
    padding: 0;
    width: 100%;
    /* height: 100%; */
}
.h-navbar{
  height: 8vh;
}



.bg-navbar{
  background-color: var(--color-main) !important;
}
.btn-orbit{
  background-color: var(--btn-primary) !important;
  color: #fff !important;
  border-color: var(--btn-border-primary) !important;
}
.btn-orbit :hover{
  background-color: var(--btn-primary-hover) !important;
  color: #fff !important;
  border-color: var(--btn-border-primary-hover) !important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.preloader-web {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-image: url('./img/loading.svg');
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.69);
  background-position: center;
}


